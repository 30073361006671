import { render, staticRenderFns } from "./reclaims-overview.vue?vue&type=template&id=33a87465&"
import script from "./reclaims-overview.vue?vue&type=script&lang=js&"
export * from "./reclaims-overview.vue?vue&type=script&lang=js&"
import style0 from "./reclaims-overview.vue?vue&type=style&index=0&id=33a87465&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VPagination})
