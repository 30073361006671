<script>
import RECLAIM_STATUSES from '@constants/reclaimStatuses'
import { pick } from 'lodash'
import formatDate from '@utils/format-date'
import { reclaimsOverviewMethods } from '@state/helpers/reclaimsOverview'
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    date: {
      default: null,
      type: String,
    },
    amount: {
      default: null,
      type: String,
    },
    currency: {
      default: null,
      type: String,
    },
    pdfFilename: {
      default: null,
      type: String,
    },
    progress: {
      default: 100,
      type: Number,
    },
    status: {
      default: 'RECLAIMED',
      type: String,
    },
    uuId: {
      default: null,
      type: String,
    },
  },

  data() {
    return {
      RECLAIM_STATUSES,
    }
  },

  methods: {
    ...pick(reclaimsOverviewMethods, ['setCurrentInvoice', 'getInvoicePdf']),
    onCardClick() {
      this.setCurrentInvoice(this.index)
      this.$router.push(`/invoice/${this.uuId}`)
    },

    downloadPdf() {
      event.stopPropagation()
      this.getInvoicePdf({
        pdfName: this.pdfFilename,
        invoiceId: this.uuId,
        isDownload: true,
      })
    },

    viewPdf() {
      event.stopPropagation()
      this.getInvoicePdf({
        name: this.pdfFilename,
        invoiceId: this.uuId,
        isDownload: false,
      })
    },

    cleanClass(string) {
      if (string.includes('_')) {
        return string.replace('_', '').toLowerCase()
      }
      return string.toLowerCase()
    },
    formatDate(date) {
      return formatDate(date)
    },
  },
}
</script>
<template>
  <div class="invoiceCard" @click="onCardClick">
    <v-layout class="flexRow">
      <!-- Title -->
      <v-flex class="title">
        <h3>{{ `${$t('ACCOUNT_STATEMENT')} ${formatDate(date)}` }}</h3>
      </v-flex>

      <!-- Progress bar may be added, decision pending -->
      <!-- Progress bar -->
      <!-- <v-flex class="progress">
        <v-progress-linear
          :buffer-value="50"
          color="green"
          rounded
          height="9"
          :striped="true"
          :value="progress"
        ></v-progress-linear>
        <div class="moreinfo">
          <div class="label">{{ $t(RECLAIM_STATUSES[status].label) }}:</div>
          <div class="amount">
            {{ 200 && this.$tn(200) }}
            {{ 'EUR' }}</div
          >
        </div>
      </v-flex> -->

      <v-btn
        class="naked-btn tertiary smallest view darktxt"
        @click="downloadPdf"
        >{{ $t('DOWNLOAD_PDF') }}</v-btn
      >
      <v-btn
        class="naked-btn tertiary smallest view darktxt"
        @click="viewPdf"
        >{{ $t('VIEW_PDF') }}</v-btn
      >
    </v-layout>
  </div>
</template>

<style scoped lang="scss">
@import '@design';

.currentStatus {
  flex-direction: row;
  padding: 2px 10px;
  color: #fff;
  border-radius: 4px;
}

.flexRow {
  align-items: center;
}

.pdfBtn {
  margin: 0 10px;
}

.statusOverview {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    margin: 5px 0 0 0;
    font-style: italic;
  }
}

.invoiceCard {
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 0 120px 0 30px;
  margin: 0 0 10px;
  background: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23F6F7F8' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 30px top 50%;
  border-radius: 7px;

  // hover animation
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
  transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23e6eaed' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.11);
    transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
    transform: translate3d(0, -2px, 0);
  }
}

h3 {
  font-size: 24px;
  line-height: 28px;
}

// .moreinfo {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   width: 100%;
//   margin-top: 10px;
// }

// .progress {
//   flex-direction: column;
//   justify-content: center;
//   margin-right: 30px;
//   .label,
//   .amount {
//     font-weight: 300;
//     color: $grey;
//   }
//   .label {
//     color: $grey;
//   }
//   .amount {
//     font-weight: 700;
//     color: $dark;
//   }
// }
</style>
