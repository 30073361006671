<script>
import Layout from '@layouts/list-overview/list-overview'
import ReclaimCard from '@components/reclaim-card/reclaim-card'
import InvoiceCard from '@components/invoice-card/invoice-card'
import Loader from '@components/custom-loader/custom-loader'
import Subheader from '@components/page-sub-header/page-sub-header'
import Sort from '@components/sort-selection/sort-selection'
import i18n from '@src/i18n'
import { pick } from 'lodash'
import { enterpriseComputed } from '@state/helpers/enterprise'
import {
  expenseOverviewComputed,
  expenseOverviewMethods,
} from '@state/helpers/expenseOverview'
import {
  reclaimsOverviewMethods,
  reclaimsOverviewComputed,
  reclaimsOverviewMutations,
} from '@state/helpers/reclaimsOverview'
import EnterpriseOriginToggle from '@components/enterprise-origin-toggle/enterprise-origin-toggle'
import { lunadisPayOrigin } from '@constants/origins'

const PdfModal = () => import('@components/pdf-modal/pdf-modal')

export default {
  page: {
    title: i18n.t('HEADER.RECLAIMS'),
    meta: [{ name: 'description', content: '' }],
  },
  components: {
    Layout,
    Loader,
    ReclaimCard,
    InvoiceCard,
    Subheader,
    Sort,
    PdfModal,
    EnterpriseOriginToggle,
  },
  data() {
    return {
      sortOptions: [
        {
          text: this.$i18n.t('SORT_BY.MOST_RECENT'),
          value: 'date_created',
        },
        {
          text: this.$i18n.t('SORT_BY.COUNTRY'),
          value: 'country',
        },
        {
          text: this.$i18n.t('SORT_BY.RECLAIM_STATE'),
          value: 'status',
        },
      ],
      lunadisPayOrigin,
    }
  },
  computed: {
    ...reclaimsOverviewComputed,
    ...enterpriseComputed,
    ...pick(expenseOverviewComputed, ['year']),
    currency() {
      return this.currentEnterprise.currency
    },
  },
  watch: {
    currentEnterpriseId(newValue, oldValue) {
      if (oldValue !== newValue && newValue) {
        this.getReclaims()
      }
    },
  },
  mounted() {
    if (this.currentEnterpriseId) {
      this.getReclaims()
      this.getInvoices()
    }
  },
  methods: {
    ...reclaimsOverviewMethods,
    ...reclaimsOverviewMutations,
    ...pick(expenseOverviewMethods, ['setYear']),
    setCurrentReclaim(index) {
      this.SET_CURRENT_RECLAIM(this.reclaims[index])
    },
    setCurrentReclaimsPage(page) {
      return this.setReclaimsPage(page).then(() => this.getReclaims())
    },
    setCurrentInvoicesPage(page) {
      return this.setInvoicesPage(page).then(() => this.getInvoices())
    },
    onChangeReclaimsSort(value) {
      return this.setReclaimsSort(value).then(() => this.getReclaims())
    },
    onChangeYear(value) {
      return this.setYear(value).then(() => this.getReclaims())
    },
  },
}
</script>

<template>
  <Layout>
    <PdfModal
      v-if="invoicePdf && invoicePdf.url"
      :url="invoicePdf && invoicePdf.url"
      :name="invoicePdf && invoicePdf.name"
      :on-close-modal="resetInvoicePdf"
    >
    </PdfModal>
    <Subheader
      :title="$t('RECLAIMS_TITLE')"
      :on-change="onChangeYear"
      :default-value="year"
      :tab-selected="tabSelected"
    />

    <EnterpriseOriginToggle :origin="lunadisPayOrigin">
      <template><div :class="$style.noListSelector"></div></template>
      <template slot="fallback">
        <!-- Tabs -->
        <div :class="$style.listSelector" data-test-id="reclaims-overview-tabs">
          <ul :class="$style.theTabs">
            <li
              :class="
                tabSelected === 1
                  ? [$style.activeReclaims, $style.active]
                  : $style.activeReclaims
              "
              @click="setTabSelected(1)"
              ><div :class="$style.verticalOffset">
                {{ $t('ACTIVE_RECLAIMS') }}
              </div>
            </li>
            <li
              :class="
                tabSelected === 2
                  ? [$style.invoices, $style.active]
                  : $style.invoices
              "
              @click="setTabSelected(2)"
            >
              <div :class="$style.verticalOffset">
                {{ $t('ACCOUNT_STATEMENTS') }}
              </div>
            </li>
          </ul>
        </div>
      </template>
    </EnterpriseOriginToggle>

    <div :class="$style.tabsOffset">
      <!-- SORT -->
      <Sort
        v-if="tabSelected === 1"
        :value="{
          text: '',
          value: reclaimsSort,
        }"
        :options="sortOptions"
        :on-change="onChangeReclaimsSort"
      />
      <!-- Loader -->
      <Loader v-if="getReclaimsInProgress" />

      <!-- Reclaims -->
      <div
        v-else-if="reclaims && reclaims.length && tabSelected === 1"
        :class="$style.reclaims"
      >
        <ReclaimCard
          v-for="(reclaim, index) in reclaims"
          :key="index"
          :country="reclaim.country"
          :uu-id="reclaim.id"
          :status="reclaim.status"
          :year="reclaim.year"
          :period="reclaim.period"
          :potential-reclaim="reclaim.amount_claimed"
          :progress="100"
          :currency="currency"
          :index="index"
          :reclaim-currency="reclaim.currency"
        />
        <v-pagination
          v-if="reclaimsPagination.total > reclaimsPagination.pageSize"
          :value="reclaimsPagination.page"
          class="custom"
          total-visible="7"
          :next-icon="$t('NEXT')"
          :prev-icon="$t('PREVIOUS')"
          :length="
            Math.ceil(reclaimsPagination.total / reclaimsPagination.pageSize)
          "
          @input="setCurrentReclaimsPage"
        />
      </div>

      <!-- Account statements -->
      <div v-else-if="tabSelected === 2">
        <InvoiceCard
          v-for="(invoice, index) in invoices"
          :key="invoice.id"
          :index="index"
          :uu-id="invoice.id"
          :date="invoice.date"
          :progress="100"
          :pdf-filename="invoice.documentFileName"
        />
        <v-pagination
          v-if="invoicesPagination.total > invoicesPagination.pageSize"
          :value="invoicesPagination.page"
          class="custom"
          total-visible="7"
          :next-icon="$t('NEXT')"
          :prev-icon="$t('PREVIOUS')"
          :length="
            Math.ceil(invoicesPagination.total / invoicesPagination.pageSize)
          "
          @input="setCurrentInvoicesPage"
        />
      </div>
      <div v-else-if="reclaims && !reclaims.length" :class="$style.error">
        {{ $t('RECLAIMS.NO_RECLAIMS_TO_SHOW') }}
      </div>
    </div>
  </Layout>
</template>
<style lang="scss" module>
@import '@design';
.listSelector {
  position: relative;
  top: -55px;
  display: flex;
  justify-content: space-between;
  height: 55px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  background: rgba(lighten($dark, 4%), 0.9);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.noListSelector {
  position: relative;
  top: -55px;
  height: 55px;
}

.verticalOffset {
  position: relative;
  top: 4px;
}

.tabsOffset {
  position: relative;
  top: -55px;
}

.reclaims {
  margin-top: 10px;
}
.error {
  margin-top: 35px;
}
.hide {
  display: none;
}
.theTabs {
  padding: 0 !important;
  li {
    height: 55px;
    padding: 0 39px 0 55px;
    margin-top: 5px;
    margin-right: 2px;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: white;
    background-color: rgba(lighten($dark, 6%), 1);
    background-repeat: no-repeat;
    background-position: calc(0% + 24px) calc(50%);
    background-size: 19px 17px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: all 0.3s cubic-bezier(0.36, 0.69, 0.43, 1.16);
    transform: translate3d(0, -5px, 0);
    &:hover {
      cursor: pointer;
      background-color: rgba(lighten($dark, 9%), 1);
      transition: all 0.1s ease-in-out;
    }
    &.active {
      position: relative;
      top: 1px;
      height: 55px;
      color: $dark;
      cursor: pointer;
      background-color: white;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      transition: all 0.3s cubic-bezier(0.36, 0.69, 0.43, 1.16);
      transform: translate3d(0, -5px, 0);
    }
    &:first-child {
      border-top-left-radius: 7px !important;
    }
  }
}

.activeReclaims {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFBB37' fill-rule='nonzero'%3E%3Cpath d='M4 12H1a1 1 0 010-2h3v2zM15 2H1a1 1 0 010-2h14a1 1 0 010 2zM4 7H1a1 1 0 010-2h3v2zM15.707 12.293l-2.274-2.274A3.947 3.947 0 0014 8c0-2.206-1.794-4-4-4S6 5.794 6 8s1.794 4 4 4c.74 0 1.424-.215 2.019-.567l2.274 2.274 1.414-1.414zM10 10c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z'/%3E%3C/g%3E%3C/svg%3E%0A");
  &.active {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23222F44' fill-rule='nonzero'%3E%3Cpath d='M4 12H1a1 1 0 010-2h3v2zM15 2H1a1 1 0 010-2h14a1 1 0 010 2zM4 7H1a1 1 0 010-2h3v2zM15.707 12.293l-2.274-2.274A3.947 3.947 0 0014 8c0-2.206-1.794-4-4-4S6 5.794 6 8s1.794 4 4 4c.74 0 1.424-.215 2.019-.567l2.274 2.274 1.414-1.414zM10 10c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}
.invoices {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFBB37' fill-rule='nonzero'%3E%3Cpath d='M15 7H1c-.6 0-1 .4-1 1v7c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V8c0-.6-.4-1-1-1zM14 2H7.4L5.7.3C5.5.1 5.3 0 5 0H2c-.6 0-1 .4-1 1v4h14V3c0-.6-.4-1-1-1z'/%3E%3C/g%3E%3C/svg%3E%0A");
  &.active {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23222F44' fill-rule='nonzero'%3E%3Cpath d='M15 7H1c-.6 0-1 .4-1 1v7c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V8c0-.6-.4-1-1-1zM14 2H7.4L5.7.3C5.5.1 5.3 0 5 0H2c-.6 0-1 .4-1 1v4h14V3c0-.6-.4-1-1-1z'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}
</style>
