<script>
import RECLAIM_STATUSES from '@constants/reclaimStatuses'
import EnterpriseOriginToggle from '@components/enterprise-origin-toggle/enterprise-origin-toggle.vue'
import { lunadisPayOrigin } from '@constants/origins'

export default {
  components: {
    EnterpriseOriginToggle,
  },
  props: {
    country: {
      default: null,
      type: String,
    },
    period: {
      default: null,
      type: String,
    },
    potentialReclaim: {
      default: null,
      type: Number,
    },
    progress: {
      default: 100,
      type: Number,
    },
    currency: {
      default: null,
      type: String,
    },
    reclaimCurrency: {
      default: null,
      type: String,
    },
    status: {
      default: null,
      type: String,
    },
    uuId: {
      default: null,
      type: String,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      RECLAIM_STATUSES,
      lunadisPayOrigin,
    }
  },
  methods: {
    onCardClick() {
      this.$router.push(`/reclaim/${this.country}/${this.period}/${this.uuId}`)
    },
    setQuarterRange(period) {
      const ranges = {
        Q1: '01/01 - 31/03',
        Q2: '01/01 - 30/06',
        Q3: '01/01 - 30/09',
        Q4: '01/01 - 31/12',
      }
      return ranges[period]
    },
    getStatusClass(string) {
      return string.replace('_', '').toLowerCase()
    },
  },
}
</script>
<template>
  <div class="reclaimCard" @click="onCardClick">
    <v-layout>
      <v-flex class="flag">
        <img
          :src="require(`../../assets/flags/${country}.png`)"
          :alt="country"
          loading="lazy"
          height="34"
          width="48"
        />
      </v-flex>
      <v-flex class="title">
        <h3>{{ $t(`ALPHA3_COUNTRY.${country}`) }}</h3>
      </v-flex>
      <v-flex v-if="status" class="statusOverview">
        <div :class="`currentStatus ${getStatusClass(status)}`">{{
          $t(RECLAIM_STATUSES[status].label)
        }}</div>
      </v-flex>
      <v-flex class="period">
        <div>
          <span>{{ $t('PERIOD') }}</span>
          {{ setQuarterRange(period) }}
        </div>
      </v-flex>
      <v-flex class="progress">
        <EnterpriseOriginToggle :origin="lunadisPayOrigin">
          <template slot="fallback">
            <v-progress-linear
              v-if="status === 'NEED_MORE_INFO'"
              v-model="potentialReclaim"
              color="bgcolor__ready-for-reclaim"
              background-color="bgcolor__light-grey"
              height="9"
              :value="progress"
              data-test-id="reclaim-card-progress"
            ></v-progress-linear>
            <v-progress-linear
              v-else
              color="blue"
              rounded
              height="9"
              :value="progress"
              data-test-id="reclaim-card-progress"
            ></v-progress-linear>
          </template>
        </EnterpriseOriginToggle>
        <div class="moreinfo">
          <div class="label">{{ $t('POTENTIAL_RECLAIM') }}:</div>
          <div v-if="reclaimCurrency && potentialReclaim" class="amount">
            {{ $tn(potentialReclaim) }}
            {{ reclaimCurrency }}</div
          >
        </div>
      </v-flex>
      <div v-if="status !== 'SUBMITTED'" class="locked">
        <EnterpriseOriginToggle :origin="lunadisPayOrigin">
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small class="lockedIcon" v-on="on">lock</v-icon>
              </template>
              <span>
                {{ $t('RECLAIM_EXPENSES_SUBMITTED') }}
              </span>
            </v-tooltip>
          </template>
          <template slot="fallback">
            <v-icon small class="lockedIcon">lock</v-icon>
          </template>
        </EnterpriseOriginToggle>
      </div>
    </v-layout>
  </div>
</template>

<style scoped lang="scss">
@import '@design';

.currentStatus {
  flex-direction: row;
  padding: 2px 10px;
  color: #fff;
  border-radius: 4px;
}

.locked {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-items: center;
  width: 26px;
  height: 26px;
  padding: 5px;
  background: $alert-error;
  border-radius: 50%;
}

.lockedIcon {
  color: white;
}

// This should be removed when API task is complete, note the spelling mistake of "MANUEL REVIEW"
.reviewfile,
.reviewiban,
.review,
.autoreview,
.manueleview {
  background-color: $in-review;
}

.returned {
  background-color: $update-needed;
}

.approved,
.sent {
  background-color: $sent-to-authorities;
}

.submitted {
  background-color: $submitted;
}

.reclaimed {
  background-color: $reclaimed;
}

.statusOverview {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    margin: 5px 0 0 0;
    font-style: italic;
  }
}

.reclaimCard {
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 0 120px 0 30px;
  margin: 0 0 10px;
  background: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23F6F7F8' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 30px top 50%;
  border-radius: 7px;

  // hover animation
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
  transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23e6eaed' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.11);
    transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
    transform: translate3d(0, -2px, 0);
  }
}

.flex {
  position: relative;
  display: flex;
  flex: auto;
  align-items: center;
  padding: 0 0 0 30px;
  margin-left: 30px;
  font-size: 12px;
  font-weight: 700;
  color: $dark;
  border-left: 1px solid $light-grey;

  span {
    margin-right: 10px;
    font-weight: 300;
    color: $grey;
  }

  &:first-child {
    border: none;
  }

  &.flag {
    max-width: 70px;
    padding-left: 0;
    margin: 0 20px 0 0;

    img {
      border-radius: 4px;
    }
  }

  &.title {
    flex: 1;
    flex-grow: 100;
    padding-left: 0;
    margin-left: 0;
    font-size: 24px;
    font-weight: 600;
    color: $dark;
    text-align: left;
    border-left: 0;
  }

  &.period {
    min-width: 110px;
  }
  &.needInfo {
    min-width: 170px;
  }
  &.progress {
    min-width: 320px;
  }
  &.status {
    min-width: 150px;
  }
}

h3 {
  font-size: 24px;
  line-height: 28px;
}

.moreinfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.progress {
  flex-direction: column;
  .label,
  .amount {
    font-weight: 300;
    color: $grey;
  }
  .label {
    color: $grey;
  }
  .amount {
    font-weight: 700;
    color: $dark;
  }
}
</style>
