var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(_vm.invoicePdf && _vm.invoicePdf.url)?_c('PdfModal',{attrs:{"url":_vm.invoicePdf && _vm.invoicePdf.url,"name":_vm.invoicePdf && _vm.invoicePdf.name,"on-close-modal":_vm.resetInvoicePdf}}):_vm._e(),_c('Subheader',{attrs:{"title":_vm.$t('RECLAIMS_TITLE'),"on-change":_vm.onChangeYear,"default-value":_vm.year,"tab-selected":_vm.tabSelected}}),_c('EnterpriseOriginToggle',{attrs:{"origin":_vm.lunadisPayOrigin}},[[_c('div',{class:_vm.$style.noListSelector})],_c('template',{slot:"fallback"},[_c('div',{class:_vm.$style.listSelector,attrs:{"data-test-id":"reclaims-overview-tabs"}},[_c('ul',{class:_vm.$style.theTabs},[_c('li',{class:_vm.tabSelected === 1
                ? [_vm.$style.activeReclaims, _vm.$style.active]
                : _vm.$style.activeReclaims,on:{"click":function($event){return _vm.setTabSelected(1)}}},[_c('div',{class:_vm.$style.verticalOffset},[_vm._v(" "+_vm._s(_vm.$t('ACTIVE_RECLAIMS'))+" ")])]),_c('li',{class:_vm.tabSelected === 2
                ? [_vm.$style.invoices, _vm.$style.active]
                : _vm.$style.invoices,on:{"click":function($event){return _vm.setTabSelected(2)}}},[_c('div',{class:_vm.$style.verticalOffset},[_vm._v(" "+_vm._s(_vm.$t('ACCOUNT_STATEMENTS'))+" ")])])])])])],2),_c('div',{class:_vm.$style.tabsOffset},[(_vm.tabSelected === 1)?_c('Sort',{attrs:{"value":{
        text: '',
        value: _vm.reclaimsSort,
      },"options":_vm.sortOptions,"on-change":_vm.onChangeReclaimsSort}}):_vm._e(),(_vm.getReclaimsInProgress)?_c('Loader'):(_vm.reclaims && _vm.reclaims.length && _vm.tabSelected === 1)?_c('div',{class:_vm.$style.reclaims},[_vm._l((_vm.reclaims),function(reclaim,index){return _c('ReclaimCard',{key:index,attrs:{"country":reclaim.country,"uu-id":reclaim.id,"status":reclaim.status,"year":reclaim.year,"period":reclaim.period,"potential-reclaim":reclaim.amount_claimed,"progress":100,"currency":_vm.currency,"index":index,"reclaim-currency":reclaim.currency}})}),(_vm.reclaimsPagination.total > _vm.reclaimsPagination.pageSize)?_c('v-pagination',{staticClass:"custom",attrs:{"value":_vm.reclaimsPagination.page,"total-visible":"7","next-icon":_vm.$t('NEXT'),"prev-icon":_vm.$t('PREVIOUS'),"length":Math.ceil(_vm.reclaimsPagination.total / _vm.reclaimsPagination.pageSize)},on:{"input":_vm.setCurrentReclaimsPage}}):_vm._e()],2):(_vm.tabSelected === 2)?_c('div',[_vm._l((_vm.invoices),function(invoice,index){return _c('InvoiceCard',{key:invoice.id,attrs:{"index":index,"uu-id":invoice.id,"date":invoice.date,"progress":100,"pdf-filename":invoice.documentFileName}})}),(_vm.invoicesPagination.total > _vm.invoicesPagination.pageSize)?_c('v-pagination',{staticClass:"custom",attrs:{"value":_vm.invoicesPagination.page,"total-visible":"7","next-icon":_vm.$t('NEXT'),"prev-icon":_vm.$t('PREVIOUS'),"length":Math.ceil(_vm.invoicesPagination.total / _vm.invoicesPagination.pageSize)},on:{"input":_vm.setCurrentInvoicesPage}}):_vm._e()],2):(_vm.reclaims && !_vm.reclaims.length)?_c('div',{class:_vm.$style.error},[_vm._v(" "+_vm._s(_vm.$t('RECLAIMS.NO_RECLAIMS_TO_SHOW'))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }